var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createStatsClient, getAllStatsClient, getSingleStatsClient, deleteStatsClient, deleteManyStatsClient, } from './action';
var initialState = {
    statsClients: { docs: [], meta: {} },
    statsClient: {},
    statClientId: undefined,
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'statsClient',
    initialState: initialState,
    reducers: {
        reset: function (state) {
            state.statsClient = {};
        },
        setCurrentStatClientId: function (state, action) {
            state.statClientId = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(createStatsClient.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createStatsClient.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.statsClients.docs = __spreadArray(__spreadArray([], state.statsClients.docs, true), [payload], false);
        })
            .addCase(createStatsClient.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getAllStatsClient.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllStatsClient.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.statsClients = payload.data;
        })
            .addCase(getAllStatsClient.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getSingleStatsClient.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getSingleStatsClient.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.statsClient = __assign({}, payload.data);
        })
            .addCase(getSingleStatsClient.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(deleteStatsClient.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteStatsClient.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.statsClients.docs = state.statsClients.docs.filter(function (stat) { return stat._id !== payload.id; });
        })
            .addCase(deleteStatsClient.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(deleteManyStatsClient.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteManyStatsClient.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.statsClients.docs = state.statsClients.docs.filter(function (stat) { return !payload.statClientIds.includes(stat._id); });
        })
            .addCase(deleteManyStatsClient.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export var setCurrentStatClientId = slice.actions.setCurrentStatClientId;
export default slice.reducer;
